import React, { useEffect, useState } from "react";
import Header from "../common/components/header/header";
import Navbar from "../common/components/navbar/navbar";
import { getStripeTransactions } from "./Network";
import moment from "moment";
import { Col, Row } from "antd";
import Grid from "../common/components/grid/grid";
function Payments() {
	const [dataSource, updateDataSource] = useState([]);
	const [startingAfter, updateStartingAfter] = useState(null);
	const [loading, updateLoading] = useState(false);
	useEffect(() => {
		let paymentsList = [];
		if (startingAfter !== false) {
			updateLoading(true);
			getStripeTransactions(
				startingAfter,
				(success) => {
					paymentsList = [...dataSource, ...success.data];
					if (success.has_more) {
						paymentsList = paymentsList.filter(
							(payment) =>
								payment.description?.includes("(Session_Details)") ||
								payment.description?.includes("(Package Payment)")
						);
						paymentsList = paymentsList.sort(function (a, b) {
							// Turn your strings into dates, and then subtract them
							// to get a value that is either negative, positive, or zero.
							return new Date(b.created) - new Date(a.created);
						});
						updateDataSource(paymentsList);
						updateStartingAfter(success.data[success.data.length - 1].id);
					} else {
						updateStartingAfter(false);
						updateLoading(false);
					}
				},
				(fail) => {
					console.log(fail);
				}
			);
		}
		// eslint-disable-next-line
	}, [startingAfter]);
	const columns = [
		{
			title: "Payment type",
			dataIndex: "paymentType",
			key: "paymentType",
			render: (clientName, record) => {
				return <>{record.description.split("-")[0]}</>;
			},
		},
		{
			title: "Client Name",
			dataIndex: "clientName",
			key: "clientName",
			render: (clientName, record) => {
				return <>{record.description.split("-")[2]}</>;
			},
		},
		{
			title: "Client Email",
			dataIndex: "clientEmail",
			key: "clientEmail",
			render: (clientName, record) => {
				return <>{record.description.split("-")[1]}</>;
			},
		},
		{
			title: "Guru Name",
			dataIndex: "guruName",
			key: "guruName",
			render: (clientName, record) => {
				return <>{record.description.split("-")[4]}</>;
			},
		},
		{
			title: "Guru Email",
			dataIndex: "guruEmail",
			key: "guruEmail",
			render: (clientName, record) => {
				return <>{record.description.split("-")[3]}</>;
			},
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
		},
		{
			title: "Transaction Date",
			dataIndex: "created",
			key: "created",
			render: (created, record) => {
				return <>{moment(created * 1000).format("DD-MM-YYYY hh:mm a")}</>;
			},
		},
		{
			title: "Promocode Percent",
			dataIndex: "promocode",
			key: "promocode",
			render: (promocode, record) => {
				const promoCodePercent = record.description.split("-")[5];
				return <>{promoCodePercent ? promoCodePercent + "%" : "-"}</>;
			},
		},
		{
			title: "Client's payment",
			dataIndex: "clientPayment",
			key: "clientPayment",
			render: (clientPayment, record) => {
				return <>{record?.amount / 100}</>;
			},
		},
		{
			title: "Stripe amount",
			dataIndex: "fee",
			key: "fee",
			render: (clientPayment, record) => {
				return <>{record?.fee / 100}</>;
			},
		},
		{
			title: "Guru Amount",
			dataIndex: "guruAmount",
			key: "guruAmount",
			render: (guruAmount, record) => {
				return (
					<>
						{/* {record.description.split("-")[5] === 0
							? (record?.amount - record?.fee) / 100
							: (record?.amount - record?.fee) /
							  100 /
							  (1 - record.description.split("-")[5] / 100)} */}
						{(record?.amount - record?.fee) / 100}
					</>
				);
			},
		},
		// {
		// 	title: "Welo Amount",
		// 	dataIndex: "weloAmount",
		// 	key: "weloAmount",
		// 	render: (weloAmount, record) => {
		// 		return (
		// 			<>
		// 				{record.description.split("-")[5] === 0
		// 					? record?.amount / 100
		// 					: record?.amount
		// 			</>
		// 		);
		// 	},
		// },
		{
			title: "Currency",
			dataIndex: "currency",
			key: "currency",
		},
	];

	return (
		<div className="payments">
			<Navbar />
			<Header parent="Payments" />
			<Row>
				<Col xs={24}>
					<Grid
						dataSource={dataSource}
						columns={columns}
						parent="Payments"
						loading={loading}
					/>
				</Col>
			</Row>
		</div>
	);
}

export default Payments;
