import React, { useEffect, useState } from "react";
import { Row, Col, Button, Space, Input } from "antd";
import { Switch } from "antd";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import Navbar from "../common/components/navbar/navbar";
import Header from "../common/components/header/header";
//import SideMenu from "../common/components/sideMenu/sideMenu";
import Grid from "../common/components/grid/grid";
import { getAllUsers, ActivatedAndDeactivateUsers } from "./network";
import { mediaBaseUrl } from "../../services";
import ClientModal from "./components/clientModal/clientModal";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import moment from "moment";

function Clients() {
	const [users, setUsers] = useState([]);
	const [isModalVisible, toggleModal] = useState(false);
	const [record, setRecord] = useState({});
	const [searchText, setSearchText] = useState("");
	const [searchedColumn, setSearchedColumn] = useState("");

	useEffect(() => {
		getAllUsers(
			(res) => setUsers(res.users),
			(err) => console.log(err)
		);
	}, []);

	const handleSearch = (selectedKeys, confirm, dataIndex) => {
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	};

	const handleReset = (clearFilters) => {
		clearFilters();
		setSearchText("");
	};

	const getColumnSearchProps = (dataIndex) => ({
		filterDropdown: ({
			setSelectedKeys,
			selectedKeys,
			confirm,
			clearFilters,
		}) => (
			<div style={{ padding: 8 }}>
				<Input
					ref={(node) => {
						//			searchInput = node;
					}}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) =>
						setSelectedKeys(e.target.value ? [e.target.value] : [])
					}
					//value={searchText[0]}
					onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
					style={{ marginBottom: 8, display: "block" }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90 }}>
						Search
					</Button>
					<Button
						onClick={() => handleReset(clearFilters)}
						size="small"
						style={{ width: 90 }}>
						Reset
					</Button>
				</Space>
			</div>
		),
		onFilter: (value, record) =>
			record[dataIndex]
				? record[dataIndex]
						.toString()
						.toLowerCase()
						.includes(value.toLowerCase())
				: "",
		onFilterDropdownVisibleChange: (visible) => {
			if (visible) {
				//	setTimeout(() => searchInput.select(), 100);
			}
		},
		render: (text) =>
			searchedColumn === dataIndex ? (
				<Highlighter
					highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
					searchWords={[searchText]}
					autoEscape
					textToHighlight={text ? text.toString() : ""}
				/>
			) : (
				text
			),
	});

	const columns = [
		{
			title: "",
			dataIndex: "image",
			key: "image",
			render: (image, _) => {
				return (
					<div className="avatarContainer">
						{image ? (
							<img src={mediaBaseUrl + image} alt="avatar" />
						) : (
							<span className="initials">
								{_.clientFirstName[0] + _.clientLastName[0]}
							</span>
						)}
					</div>
				);
			},
			onCell: (record, rowIndex) => ({
				onClick: () => {
					setRecord(record);
					toggleModal(!isModalVisible);
				},
			}),
		},
		{
			title: "Client Name",
			dataIndex: "clientName",
			key: "clientName",
			sorter: (a, b) => {
				if (a.clientName < b.clientName) return -1;
				else return 1;
			},
			onCell: (record, rowIndex) => ({
				onClick: () => {
					setRecord(record);
					toggleModal(!isModalVisible);
				},
			}),
			...getColumnSearchProps("clientName"),
			filterIcon: (filtered) => (
				<SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
			),
		},
		{
			title: "Date Of Birth",
			dataIndex: "dateOfBirth",
			key: "dateOfBirth",
			sorter: (a, b) =>
				new Date(a.dateOfBirth).getTime() - new Date(b.dateOfBirth).getTime(),
			onCell: (record, rowIndex) => ({
				onClick: () => {
					setRecord(record);
					toggleModal(!isModalVisible);
				},
			}),

			render: (dateOfBirth) => {
				return <div>{moment(dateOfBirth).format("DD/MM/YYYY")}</div>;
			},
		},
		{
			title: "Email",
			dataIndex: "email",
			key: "email",
			sorter: (a, b) => {
				if (a.email < b.email) return -1;
				else return 1;
			},
			onCell: (record, rowIndex) => ({
				onClick: () => {
					setRecord(record);
					toggleModal(!isModalVisible);
				},
			}),
		},
		{
			title: "Phone Number",
			dataIndex: "phoneNumber",
			key: "phoneNumber",
			sorter: (a, b) => {
				if (a.phoneNumber < b.phoneNumber) return -1;
				else return 1;
			},
			onCell: (record, rowIndex) => ({
				onClick: () => {
					setRecord(record);
					toggleModal(!isModalVisible);
				},
			}),
		},
		{
			title: "Joined Date",
			dataIndex: "createdAt",
			key: "createdAt",
			render: (createdAt) => moment(createdAt).format("DD-MM-YYYY"),
			sorter: (a, b) => {
				if (a.createdAt < b.createdAt) return -1;
				else return 1;
			},
		},
		{
			title: "Sessions Count",
			dataIndex: "sessionsCount",
			key: "sessionsCount",
			sorter: (a, b) => {
				if (a.sessionsCount < b.sessionsCount) return -1;
				else return 1;
			},
		},
		{
			title: "Block",
			dataIndex: "isActive",
			key: "isActive",
			render: (isActive, _) => {
				return (
					<div>
						<Switch
							className={"switch"}
							checkedChildren={<CheckOutlined />}
							unCheckedChildren={<CloseOutlined />}
							defaultChecked={!isActive}
							key={isActive}
							onChange={(checked) =>
								ActivatedAndDeactivateUsers(
									{
										isActive: !checked,
										id: _._id,
										type: 1,
									},
									(success) => {
										getAllUsers(
											(res) => setUsers(res.users),
											(err) => console.log(err)
										);
									},
									(fail) => console.log(fail)
								)
							}
						/>
					</div>
				);
			},
		},
	];
	return (
		<div className="clients">
			<Navbar />
			<Header parent="Clients" />
			<Row>
				{/* <Col xs={4}>
					<SideMenu current="Clients" />
				</Col> */}
				<Col xs={24}>
					<Grid dataSource={users} columns={columns} parent="Client" />
				</Col>
			</Row>
			<ClientModal
				isModalVisible={isModalVisible}
				toggleModal={() => {
					toggleModal(!isModalVisible);
					getAllUsers(
						(res) => setUsers(res.users),
						(err) => console.log(err)
					);
				}}
				record={record}
			/>
		</div>
	);
}

export default Clients;
