import React, { useEffect, useState, useRef } from "react";
import { Modal } from "antd";
import send from "../../../../assets/icons/send.svg";
import { mediaBaseUrl } from "../../../../services";
import firebase from "firebase/app";
import moment from "moment";
import "./liveChatModal.css";
import "firebase/messaging";
import "firebase/firestore";
import "firebase/firebase-database";
import "firebase/auth";

function LiveChatModal({ isModalVisible, toggleModal, record }) {
	const [msgsArr, setmsgsArr] = useState([]);
	const [msgData, setMsgData] = useState("");
	const dummy = useRef();
	let name = [];

	const getChatMsgs = () => {
		const db = firebase.database();
		var usersRef = db.ref("messages/" + record.key);
		usersRef.on("value", (snapshot) => {
			const values = Object.values(snapshot.val());
			setmsgsArr(values);
		});
	};

	useEffect(() => {
		if (record) {
			getChatMsgs();
			firebase
				.database()
				.ref("users/" + record.key + "/isAdminRead")
				.set(true);
		}
		// eslint-disable-next-line
	}, []);

	const handleSend = (e) => {
		e.preventDefault();
		if (msgData.length !== 0) {
			firebase
				.database()
				.ref("messages/" + record.key)
				.push({
					msg: msgData,
					createdAt: firebase.database.ServerValue.TIMESTAMP,
					uid: "admin ID",
					img: "",
					initials: "AD",
					sender: "admin",
				})
				.then(() => {
					setMsgData("");
					dummy.current.scrollIntoView({ behavior: "smooth" });
				});

			firebase
				.database()
				.ref("users/" + record.key + "/isUserRead")
				.set(false);
		}
	};
	if (msgsArr && msgsArr.length > 0) {
		name = msgsArr[0].firstName + " " + msgsArr[0].lastName;
	}
	return (
		<Modal
			visible={isModalVisible}
			onCancel={toggleModal}
			footer={[]}
			width={"50%"}
			centered
			className="liveChatModal">
			<div className="x">
				<h6>
					<span>Chat</span> - {name}
				</h6>
				<div className="chatContainer">
					{msgsArr &&
						msgsArr.map((message, i) => {
							return (
								<div
									key={i}
									className={
										message.sender === "admin"
											? "msgContainer sender"
											: "msgContainer reciever"
									}>
									{/* {message.img.length > 0 ? (
										<img className="initials" src={message.img} alt="userImg" />
									) : (
										<div className="initials">{message.initials}</div>
									)} */}
									{message.sender === "admin" ? (
										<div className="initials">{message.initials}</div>
									) : record.image.length > 0 ? (
										<img
											className="initials"
											src={mediaBaseUrl + record.image}
											alt="userImg"
										/>
									) : (
										<div className="initials">{message.initials}</div>
									)}

									<div
										className={
											message.sender === "admin" ? "msg sender" : "msg reciever"
										}>
										{message.msg}
									</div>
									{/* <div>
										{moment(message.createdAt).format('DD-MM-YYYY hh:mm a')}
									</div> */}
									{new Date(message.createdAt).toString() !==
										"Invalid Date" && (
										<div className="timeStamp">
											{moment(message.createdAt).format("DD-MM-YYYY hh:mm a")}
										</div>
									)}
								</div>
							);
						})}
					<div ref={dummy}></div>
				</div>

				<form className="textContainer" onSubmit={handleSend}>
					<div>
						<input
							type="text "
							value={msgData}
							onChange={(e) => setMsgData(e.target.value)}
						/>
						<button style={{ border: "none", outline: "#fff" }}>
							<img src={send} alt="send" />
						</button>
					</div>
				</form>
			</div>
		</Modal>
	);
}

export default LiveChatModal;
